import React, { useState } from 'react';
import DataService from '../../../classes/DataService';
import StoryFunctions from '../../../classes/StoryFunctions';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const EndSprint = ({ 
    //stories, 
    //sprints, 
    setSprints, 
    open, 
    onClose,
    projectContext,
    setProjectContext,
    setRefreshStories
}) => {
    const [loading, setLoading] = useState(false);
    
    const endSprint = async () => {
        setLoading(true);
        // Use dataService to end the currently active sprint
        const activeSprint = projectContext.SPRINTS.find(sprint => sprint.is_active);

        if (activeSprint) {
            // Call deactivateDoneStories function
            await StoryFunctions.deactivateDoneStories(projectContext.STORIES);

            // Set the end_date and is_active attributes
            const now = new Date();
            const timezoneOffsetInHours = now.getTimezoneOffset() / 60;
            const localISOTime = new Date(now.getTime() - timezoneOffsetInHours * 3600 * 1000).toISOString().slice(0, -1);
            activeSprint.end_date = localISOTime;
            activeSprint.is_active = false;

            const updatedSprint = await DataService.updateSprint(activeSprint);
            setSprints(prevSprints => prevSprints.map(sprint => sprint.sprint_id === updatedSprint.sprint_id ? updatedSprint : sprint));            
            setProjectContext(prevContext => ({
                ...prevContext,
                SPRINTS: prevContext.SPRINTS.map(sprint => sprint.sprint_id === updatedSprint.sprint_id ? updatedSprint : sprint),
                STORIES: prevContext.STORIES.map(story =>
                    story.status === 'done'
                        ? { ...story, status: 'inactive', story_location: 'inactive' }
                        : story
                )
            }));

            // Ensure the component re-renders even if refreshStories is already true 
            setRefreshStories(prev => !prev);
        }

        setLoading(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>End Sprint</DialogTitle>
            <DialogContent>
                Are you sure you want to end the sprint?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="error" disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={endSprint} color="primary" disabled={loading}>
                    End Sprint
                </Button>
            </DialogActions>
            {loading && (
                <Box>
                <CircularProgress
                    size={20}
                    style={{ position: 'absolute', bottom: '20px', left: '20px' }}
                />
                </Box>
            )}
        </Dialog>
    );
};

export default EndSprint;