class DataService {
    // Define the base URL for the API
    baseURL = process.env.REACT_APP_NODE_SERVICE_HOST || 'http://localhost:3002';


    // CRUD OPERATIONS FOR PROJECTS
    async getProjectDetails(id) {
        const response = await fetch(`${this.baseURL}/projects/${id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        return data ? JSON.parse(data) : null;
    }

    async getProjectMembers(projectId) {
        const response = await fetch(`${this.baseURL}/projectmembers/${projectId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        return data ? JSON.parse(data) : null;
    }

    async addProjectMember(project_id, user_id, memberEmail) {
        const response = await fetch(`${this.baseURL}/projectmembers/${project_id}/${user_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        const memberData = data ? JSON.parse(data) : null;

        // If member was added successfully, send the invite
        if (memberData) {
            const inviteResponse = await fetch(`${this.baseURL}/send-user-member-invite/invite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "to": memberEmail })
            });
            if (!inviteResponse.ok) {
                throw new Error('Failed to send invite');
            }
            // console.log('Invite sent successfully');
        }

        return memberData;
    }

    async deleteProjectMember(project_id, user_id, userEmail) {
        const response = await fetch(`${this.baseURL}/projectmembers/${project_id}/${user_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // If member was deleted successfully (status 200 or 204), send the removal notification
        if (response.status === 200 || response.status === 204) {
            const removeResponse = await fetch(`${this.baseURL}/send-user-member-invite/remove`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "to": userEmail })
            });
            if (!removeResponse.ok) {
                throw new Error('Failed to send removal notification');
            }
            // console.log('Removal notification sent successfully');
        }

        const data = await response.text();
        return data ? JSON.parse(data) : null;
    }

    async getCollabProjects(userId) {
        const response = await fetch(`${this.baseURL}/projectmembers/projects/${userId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        return data ? JSON.parse(data) : null;
    }

    async updateProjectDetails(project_id, projectData) {
        const response = await fetch(`${this.baseURL}/projects/${project_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(projectData),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const updatedProject = await response.json();
        return updatedProject;
    }

    // CRUD OPERATIONS FOR EPICS
    async getEpicsByProjectId(projectId) {
        const response = await fetch(`${this.baseURL}/epics/project/${projectId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        return data ? JSON.parse(data) : null;
    }

    async getEpicByID(epic_id) {
        const response = await fetch(`${this.baseURL}/epics/${epic_id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        return data ? JSON.parse(data) : null;
    }

    async updateEpic(id, data) {
        const response = await fetch(`${this.baseURL}/epics/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const updatedData = await response.text();
        return updatedData ? JSON.parse(updatedData) : null;
    }

    async createEpic(data) {
        const response = await fetch(`${this.baseURL}/epics`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const createdData = await response.text();
        return createdData ? JSON.parse(createdData) : null;
    }

    async deleteEpic(epic_id, project_id) {
        // Fetch all user stories of the project
        const userStories = await this.getUserStoriesByProjectId(project_id);
        // console.log("User Stories: " + JSON.stringify(userStories));

        // Iterate over the user stories
        for (let story of userStories) {
            // Check if the epic_id of the user story matches the id of the epic being deleted
            // console.log("StoryId: " + story.user_story_id + " EpicId: " + story.epic_id + " EpicIdToDelete: " + epic_id);
            if (story.epic_id === epic_id) {
                // Update the epic_id of the user story to null
                story.epic_id = null;
                await this.updateUserStory(story.user_story_id, story);
            }
        }

        // Delete the epic
        const response = await fetch(`${this.baseURL}/epics/${epic_id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const deletedData = await response.text();
        return deletedData ? JSON.parse(deletedData) : null;
    }

    // CRUD OPERATIONS FOR USER STORIES
    async getUserStoriesByProjectId(projectId) {
        const response = await fetch(`${this.baseURL}/user_stories/project/${projectId}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.text();
        return data ? JSON.parse(data) : null;
    }

    async createUserStory(data) {
        // Check if data.acceptance_criteria is an object
        if (typeof data.acceptance_criteria === 'object' && data.acceptance_criteria !== null) {
            // Convert the object into a numbered list string
            let criteriaList = '';
            let i = 1;
            for (let key in data.acceptance_criteria) {
                criteriaList += `${i}. ${data.acceptance_criteria[key]}\n`;
                i++;
            }
            data.acceptance_criteria = criteriaList;
        }

        const response = await fetch(`${this.baseURL}/user_stories`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const createdData = await response.text();
        return createdData ? JSON.parse(createdData) : null;
    }

    async updateUserStory(user_story_id, data) {
        // Check the attributes and set them to null if they're an empty string or undefined
        if (!data.epic_id || data.epic_id === '') {
            data.epic_id = null;
        }

        const response = await fetch(`${this.baseURL}/user_stories/${user_story_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const updatedData = await response.text();
        return updatedData ? JSON.parse(updatedData) : null;
    }

    async updateUserStoriesLocation(userStoryIds, location) {
        if(userStoryIds.length === 0) return;
        const response = await fetch(`${this.baseURL}/user_stories/update-location`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_story_ids: userStoryIds,
                story_location: location,
            }),
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        return response;
    }

    async deleteUserStory(user_story_id) {
        const response = await fetch(`${this.baseURL}/user_stories/${user_story_id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.ok;
    }

    async getStoryComments(user_story_id) {
        const response = await fetch(`${this.baseURL}/comments/${user_story_id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const commentsData = await response.json();
        return commentsData;
    }

    async createComment(commentData) {
        const response = await fetch(`${this.baseURL}/comments`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(commentData),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const newComment = await response.json();
        return newComment;
    }

    async updateComment(comment_id, commentData) {
        const response = await fetch(`${this.baseURL}/comments/${comment_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(commentData),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const updatedComment = await response.json();
        return updatedComment;
    }

    async deleteComment(comment_id) {
        const response = await fetch(`${this.baseURL}/comments/${comment_id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.ok;
    }

    // CRUD OPERATIONS FOR SPRINTS
    async getSprintById(sprint_id) {
        const response = await fetch(`${this.baseURL}/sprints/${sprint_id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async getSprintsByProjectId(project_id) {
        const response = await fetch(`${this.baseURL}/sprints/project/${project_id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async createSprint(project_id, name, startDate) {
        const response = await fetch(`${this.baseURL}/sprints`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                project_id: project_id,
                name: name,
                start_date: new Date().toLocaleDateString('en-CA'),
                end_date: null
            }),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const newSprint = await response.json();
        return newSprint;
    }

    async updateSprint(sprint) {
        const response = await fetch(`${this.baseURL}/sprints/${sprint.sprint_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(sprint),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const updatedSprint = await response.json();
        return updatedSprint;
    }

    // Auth0 CRUD OPERATIONS
    async getAuth0UserByID(userId) {
        const response = await fetch(`${this.baseURL}/auth0/users/${userId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        return await response.json();
    }

    async getAuth0UserByEmail(email) {
        const response = await fetch(`${this.baseURL}/auth0/users-by-email/${email}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    }

    async sendAuth0EmailVerification(userId) {
        const response = await fetch(`${this.baseURL}/auth0/users/${encodeURIComponent(userId)}/send-verification-email`, {
            method: 'POST',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
            }
        });
    
        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }
    
        return response.json();
    }

    async uploadImage(file) {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch(`${this.baseURL}/azure/upload`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data.url; // assuming the response contains a url property with the image's location
    }

    async updateUserPicture(userId, pictureUrl) {
        const response = await fetch(`${this.baseURL}/auth0/users/${userId}/picture`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ picture: pictureUrl }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    }

    async deleteUserPicture(imageName) {
        const response = await fetch(`${this.baseURL}/azure/delete/${imageName}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    }

    async updateUserData(userId, userData) {
        const response = await fetch(`${this.baseURL}/auth0/users/${userId}/data`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    }

    async updateUserMetaData(userId, userMetadata) {
        const response = await fetch(`${this.baseURL}/auth0/users/${userId}/metadata`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userMetadata), // Pass userMetadata directly
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        return await response.json();
    }

    //DOCUMENT CRUD OPERATIONS
    async getDocumentsByProjectId(project_id) {
        const response = await fetch(`${this.baseURL}/documents/project/${project_id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async createDocument(data) {
        const response = await fetch(`${this.baseURL}/documents`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const createdData = await response.json();
        return createdData;
    }

    async updateDocument(document_id, data) {
        // console.log('Data:', data);
        // console.log('Document ID:', document_id);
        const response = await fetch(`${this.baseURL}/documents/${document_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const updatedData = await response.json();
        return updatedData;
    }

    async deleteDocument(document_id) {
        const response = await fetch(`${this.baseURL}/documents/${document_id}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.ok;
    }

    async getAllNews() {
        const response = await fetch(`${this.baseURL}/news`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async getLatestNews() {
        const response = await fetch(`${this.baseURL}/news/latest`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async getStripeCustomerByEmail(email) {
        const url = new URL(`${this.baseURL}/stripe/customer`);
        url.searchParams.append('email', email);
        // console.log(url.toString());
        
        const response = await fetch(url.toString());
        if (response.status === 404) {
            return 'Customer could not be found';
        }
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async getStripeSubscriptionInfo(email) {
        const url = new URL(`${this.baseURL}/stripe/customer-subscription-details`);
        url.searchParams.append('email', email);
        let billingFrequency = '';
    
        const response = await fetch(url.toString());
        
        if (response.status === 404) {
            return 'Customer or subscription details not found';
        }
        if (response.status === 500) {
            throw new Error('Internal server error');
        }
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (!data || Object.keys(data).length === 0 || data.message === 'No customer found' || data.message === 'No subscription found') {
            return 'No Stripe subscription found';
        }
        if(data.productName === 'Meteor Monthly') {
            billingFrequency = 'Monthly';
        } else if(data.productName === 'Meteor Annually') {
            billingFrequency = 'Annually';
        }
        return {
            customerId: data.customerId,
            customerName: data.customerName,
            productName: data.productName,
            subscriptionPrice: data.subscriptionPrice,
            nextBillingDate: data.nextBillingDate,
            cancelAtPeriodEnd: data.cancelAtPeriodEnd,
            billingFrequency: billingFrequency,
        };
    }

    async getCustomerPortal(email) {
        const url = new URL(`${this.baseURL}/stripe/customer-portal`);
        url.searchParams.append('email', email);

        try {
            const response = await fetch(url.toString());
            if (response.status === 404) {
                return 'Customer portal could not be found';
            }
            if (response.status === 500) {
                throw new Error('Internal server error');
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching customer portal:', error);
            throw error;
        }
    }

    async getProjectContextById(project_id) {
        const response = await fetch(`${this.baseURL}/project-context/${project_id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    }

    async batchUpdateStories(stories) {
        // console.log("Batch Update Stories",stories);
        try {
            const response = await fetch(`${this.baseURL}/user_stories/batch-update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stories),
            });
    
            if (!response.ok) 
                throw new Error('Failed to update stories');
        } catch (error) {
            console.error('Error updating stories in batch:', error);
        }
    }

    async batchCreateStories(stories) {
        try {
            const response = await fetch(`${this.baseURL}/user_stories/batch-create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(stories),
            });
    
            if (!response.ok) 
                throw new Error('Failed to create stories');

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error creating stories in batch:', error);
            throw error;
        }
    }
}

export default new DataService();