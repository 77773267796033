export default class UserStoryDto {
    constructor() {
        this.user_story_id = '';
        this.title = '';
        this.description = '';
        this.acceptance_criteria = '';
        this.priority = 0;
        this.status = '';
        this.story_points = 0;
        this.assigned_user = '';
        this.story_location = '';
        this.epic_id = '';
        this.project_id = '';
        this.sprint_id = '';
    }

    static fromJSON(json) {
        const story = new UserStoryDto();
        story.user_story_id = json.user_story_id;
        story.title = json.title;
        story.description = json.description;
        story.acceptance_criteria = json.acceptance_criteria;
        story.priority = json.priority;
        story.status = json.status;
        story.story_points = json.story_points;
        story.assigned_user = json.assigned_user;
        story.story_location = json.story_location;
        story.epic_id = json.epic_id;
        story.project_id = json.project_id;
        story.sprint_id = json.sprint_id;
        return story;
    }
}