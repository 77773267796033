import pino from 'pino';

const logger = pino({
  level: 'info',
  prettyPrint: { colorize: true },
});

export default class Logger {
  info(message, meta) {
    // logger.info(meta, message);
  }

  error(message, meta) {
    // logger.error(meta, message);
  }
}